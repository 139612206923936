<template>
  <div class="flex justify-between items-center pt-6">
    <!-- Wrapper for text -->
    <div class="flex-1 text-center">
      <p class="text-2xl font-bold text-gray-800 dark:text-white">Table of Contents:</p>
    </div>

    <!-- Buttons for navigation -->
    <div class="flex items-center gap-4 pb-3">
      <!-- Left chevron -->
      <button @click="scrollLeft" :disabled="atStart" class="disabled:opacity-50 disabled:cursor-not-allowed">
        <span class="flex items-center justify-center w-10 h-10 rounded-full bg-gray-200 dark:bg-gray-700">
          <img
            src="/img/icons/chevron-left.svg"
            class="size-4 filter brightness-0 dark:filter dark:invert dark:invert-1"
            alt="arrow-left"
          />
        </span>
      </button>

      <!-- Right chevron -->
      <button @click="scrollRight" :disabled="atEnd" class="disabled:opacity-50 disabled:cursor-not-allowed">
        <span class="flex items-center justify-center w-10 h-10 rounded-full bg-gray-200 dark:bg-gray-700">
          <img
            src="/img/icons/chevron-right.svg"
            class="size-4 filter brightness-0 dark:filter dark:invert dark:invert-1"
            alt="arrow-right"
          />
        </span>
      </button>
    </div>
  </div>

  <div class="w-full overflow-x-scroll no-scrollbar" ref="scrollContainer">
    <ul class="flex gap-4">
      <li
        v-for="section in sections"
        :key="section.sectionIndex"
        @click="
          emit('update:currentSectionIndex', section.sectionIndex + 1);
          emit('update:fullViewToC', false);
        "
        class="w-64 flex-shrink-0 flex flex-col border-2 border-gray-200 dark:border-gray-600 rounded-lg px-4 py-1 cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors"
      >
        <img :src="section.image" class="w-full aspect-video object-cover rounded-md" :alt="section.header" />
        <p class="text-xl line-clamp-2 font-semibold text-gray-800 dark:text-white">{{ section.header }}</p>
      </li>
    </ul>
  </div>

  <button
    @click="emit('update:fullViewToC', true)"
    class="text-2xl justify-self-start text-Tourscanner_blue-800 underline hover:text-Tourscanner_blue-900"
  >
    See all
  </button>
</template>

<script setup>
import { ref, onMounted, defineEmits } from 'vue';

const emit = defineEmits(['update:currentSectionIndex, update:fullViewToC']);

const props = defineProps({
  dataBlocks: {
    type: Array,
    default: () => [],
  },
  currentSectionIndex: {
    type: Number,
    default: 0,
  },
});

const sections = ref([]);

const atStart = ref(true);
const atEnd = ref(false);
const scrollContainer = ref(null);

onMounted(() => {
  if (props.dataBlocks.length > 0) {
    sections.value = props.dataBlocks
      .filter((block) => block.__component === 'block.html')
      .flatMap((block) => parseSections(block.content));
  }
  if (scrollContainer.value) {
    scrollContainer.value.addEventListener('scroll', updateScrollState);
  }
});

onUnmounted(() => {
  if (scrollContainer.value) {
    scrollContainer.value.removeEventListener('scroll', updateScrollState);
  }
});

const parseSections = (content) => {
  if (typeof window === 'undefined') return []; // Ensure it runs only on the client

  const parser = new DOMParser();
  const doc = parser.parseFromString(content, 'text/html');
  const elements = Array.from(doc.body.childNodes);

  const sections = [];
  let sectionIndex = 0;

  elements.forEach((node, index) => {
    const tagName = node.tagName?.toLowerCase();

    // Check if the node is a header (h2)
    if (tagName === 'h2') {
      // Start a new section with header
      const section = {
        sectionIndex,
        header: node.innerText || node.textContent || '', // Use plain text for header
        image: null, // Default to no image
      };

      // Check the next sibling to see if it's a div that contains an image
      const nextNode = elements[index + 1]; // Get the next sibling node
      section.image = nextNode?.nextSibling?.firstElementChild?.getAttribute('src');
      // Push the section with header and image (if available)
      sections.push(section);
      sectionIndex++;
    }
  });

  return sections;
};

const updateScrollState = () => {
  if (scrollContainer.value) {
    const { scrollLeft, scrollWidth, clientWidth } = scrollContainer.value;
    atStart.value = scrollLeft === 0;
    atEnd.value = scrollLeft + clientWidth + 50 >= scrollWidth;
  }
};

const scrollLeft = () => {
  if (scrollContainer.value) {
    const scrollAmount = 140 * 3;
    scrollContainer.value.scrollBy({ left: -scrollAmount, behavior: 'smooth' });
  }
};

const scrollRight = () => {
  if (scrollContainer.value) {
    const scrollAmount = 140 * 3;
    scrollContainer.value.scrollBy({ left: scrollAmount, behavior: 'smooth' });
  }
};
</script>

<style scoped>
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>
